import env from '@beam-australia/react-env'
import { createContext, useContext } from 'react'
import spec from 'src/types/api/spec.json'

import packageJson from '../../package.json'

export type IEnv = ReturnType<typeof Env>

export function Env() {
  function resolve(
    name: string,
    getDefaultValue: () => string = () => isMissing(name),
  ): string {
    return env(name) ?? getDefaultValue()
  }

  const APP_ENV = validateAppEnv(resolve('APP_ENV', () => 'production'))

  return {
    APP_ENV: APP_ENV,
    APP_VERSION: packageJson.version,
    APP_NAME: packageJson.name,
    APP_DISPLAY_NAME: packageJson.displayName,
    APP_RELEASE: `${packageJson.name}@${packageJson.version}` as const,
    API_VERSION: spec.info.version,

    IS_DEV: APP_ENV === 'development',

    BASE_URL: formatUrl(resolve('BASE_URL')),
    API_URL: formatUrl(resolve('API_URL')),
    MINIO_URL: formatUrl(resolve('MINIO_URL')),

    KEYCLOAK_URL: formatUrl(resolve('KEYCLOAK_URL')),
    KEYCLOAK_REALM: resolve('KEYCLOAK_REALM'),
    KEYCLOAK_CLIENT_ID: resolve('KEYCLOAK_CLIENT_ID'),

    PHOTO_SIZE_SMALL: parseInt(resolve('PHOTO_SIZE_SMALL')),
    PHOTO_SIZE_MEDIUM: parseInt(resolve('PHOTO_SIZE_MEDIUM')),
    PHOTO_SIZE_LARGE: parseInt(resolve('PHOTO_SIZE_LARGE')),

    SENTRY_DSN: resolve('SENTRY_DSN'),
  } as const
}

export const EnvContext = createContext<IEnv | undefined>(undefined)

export function useEnv(): IEnv {
  const resolved = useContext(EnvContext)

  if (!resolved) {
    throw new Error('Please provide an env object')
  }

  return resolved
}

function isMissing(name: string): never {
  throw new Error(`Missing env ${name}`)
}

function formatUrl(url: string) {
  return url.replace(/(\/)+$/, '') // remove trailing slash
}

function validateAppEnv(appEnv: string) {
  const validValues = ['development', 'production', 'integ']
  if (!validValues.includes(appEnv)) {
    throw new Error(
      `Invalid value for APP_ENV: ${JSON.stringify(
        appEnv,
      )}. Valid values are: ${validValues.join(', ')}`,
    )
  }

  return appEnv as 'development' | 'production' | 'integ'
}
