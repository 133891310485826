import type { InputName } from '@nartex/sfm-form-engine'
import BodyMIcon from '@nartex/smartforest-design-tokens/graphics/react/BodyM'
import BodySIcon from '@nartex/smartforest-design-tokens/graphics/react/BodyS'
import BarCodeIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxBarcode'
import DateIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxCalendar'
import DateTimeIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxCalendarEvent'
import CheckBoxIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxCheck'
import TextIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxFont'
import PhotoIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsPhotoAlbum'
import VideoIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsVideos'
import AudioIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsVolumeFull'
import TimeIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxTimeFive'
import HeadingMIcon from '@nartex/smartforest-design-tokens/graphics/react/HeadingM'
import HeadingSIcon from '@nartex/smartforest-design-tokens/graphics/react/HeadingS'
import AccordionIcon from '@nartex/smartforest-design-tokens/graphics/react/IconAccordion'
import MultiChoiceIcon from '@nartex/smartforest-design-tokens/graphics/react/IconMultiChoice'
import NumberIcon from '@nartex/smartforest-design-tokens/graphics/react/IconNumeric'
import PictureIcon from '@nartex/smartforest-design-tokens/graphics/react/IconPicture'
import SingleChoiceIcon from '@nartex/smartforest-design-tokens/graphics/react/IconSingleChoice'
import SignatureIcon from '@nartex/smartforest-design-tokens/graphics/react/LaSignature'
import { defineMessage } from 'react-intl'

import type { Blueprint } from './types'

const namedFieldProps = {
  label: true,
  name: true,
  required: true,
  hidden: true,
}

export const definitions: Record<InputName, Blueprint> = {
  Accordion: {
    inputName: 'Accordion',
    displayName: defineMessage({
      id: 'bluePrints.Accordion.displayName',
      defaultMessage: `Tête d'accordéon`,
    }),
    icon: <AccordionIcon />,
    props: {
      label: true,
      hidden: true,
    },
  },
  // Basics
  Text: {
    inputName: 'Text',
    displayName: defineMessage({
      id: 'bluePrints.Text.displayName',
      defaultMessage: 'Texte',
    }),
    icon: <TextIcon />,
    props: {
      ...namedFieldProps,
      multiline: true,
      defaultValue: true,
    },
  },
  Number: {
    inputName: 'Number',
    displayName: defineMessage({
      id: 'bluePrints.Number.displayName',
      defaultMessage: 'Nombre',
    }),
    icon: <NumberIcon />,
    props: {
      ...namedFieldProps,
      defaultValue: true,
      min: true,
      max: true,
      decimalCount: true,
    },
  },

  // Choices
  Checkbox: {
    inputName: 'Checkbox',
    displayName: defineMessage({
      id: 'bluePrints.Checkbox.displayName',
      defaultMessage: 'Vrai / Faux',
    }),
    icon: <CheckBoxIcon />,
    props: {
      ...namedFieldProps,
      defaultValue: true,
    },
  },
  SelectOne: {
    inputName: 'SelectOne',
    displayName: defineMessage({
      id: 'bluePrints.SelectOne.displayName',
      defaultMessage: 'Choix simple',
    }),
    icon: <SingleChoiceIcon />,
    props: {
      ...namedFieldProps,
      defaultValue: true,
      options: true,
    },
  },
  SelectMany: {
    inputName: 'SelectMany',
    displayName: defineMessage({
      id: 'bluePrints.SelectMany.displayName',
      defaultMessage: 'Choix multiple',
    }),
    icon: <MultiChoiceIcon />,
    props: {
      ...namedFieldProps,
      options: true,
    },
  },
  // Temporals
  Time: {
    inputName: 'Time',
    displayName: defineMessage({
      id: 'bluePrints.Time.displayName',
      defaultMessage: 'Heure',
    }),
    icon: <TimeIcon />,
    props: {
      ...namedFieldProps,
    },
  },
  Date: {
    inputName: 'Date',
    displayName: defineMessage({
      id: 'bluePrints.Date.displayName',
      defaultMessage: 'Jour',
    }),
    icon: <DateIcon />,
    props: {
      ...namedFieldProps,
      hasDefaultValue: true,
      min: true,
      max: true,
    },
  },
  DateTime: {
    inputName: 'DateTime',
    displayName: defineMessage({
      id: 'bluePrints.DateTime.displayName',
      defaultMessage: 'Date et Heure',
    }),
    icon: <DateTimeIcon />,
    props: {
      ...namedFieldProps,
      hasDefaultValue: true,
      min: true,
      max: true,
    },
  },

  // Media
  Photo: {
    inputName: 'Photo',
    displayName: defineMessage({
      id: 'bluePrints.Photo.displayName',
      defaultMessage: 'Prise de Photo',
    }),
    icon: <PhotoIcon />,
    props: {
      ...namedFieldProps,
      quality: true,
    },
  },
  Video: {
    inputName: 'Video',
    displayName: defineMessage({
      id: 'bluePrints.Video.displayName',
      defaultMessage: 'Enregistrement Vidéo',
    }),
    icon: <VideoIcon />,
    props: {
      ...namedFieldProps,
      quality: true,
    },
  },
  Audio: {
    inputName: 'Audio',
    displayName: defineMessage({
      id: 'bluePrints.Audio.displayName',
      defaultMessage: 'Enregistrement Audio',
    }),
    icon: <AudioIcon />,
    props: {
      ...namedFieldProps,
    },
  },
  Signature: {
    inputName: 'Signature',
    displayName: defineMessage({
      id: 'bluePrints.Signature.displayName',
      defaultMessage: 'Zone de signature',
    }),
    icon: <SignatureIcon />,
    props: {
      ...namedFieldProps,
    },
  },
  // Misc
  BarCodeReader: {
    inputName: 'BarCodeReader',
    displayName: defineMessage({
      id: 'bluePrints.BarCodeReader.displayName',
      defaultMessage: 'Scanner de Code-barre',
    }),
    icon: <BarCodeIcon />,
    props: {
      label: true,
      hidden: true,
    },
  },
  // Presentational
  PictureDisplay: {
    inputName: 'PictureDisplay',
    displayName: defineMessage({
      id: 'bluePrints.PictureDisplay.displayName',
      defaultMessage: 'Illustration',
    }),
    icon: <PictureIcon />,
    props: {
      hidden: true,
      media: true,
    },
  },

  HeadingM: {
    inputName: 'HeadingM',
    displayName: defineMessage({
      id: 'bluePrints.HeadingM.displayName',
      defaultMessage: 'Titre M',
    }),
    icon: <HeadingMIcon />,
    props: {
      label: true,
      hidden: true,
    },
  },
  HeadingS: {
    inputName: 'HeadingS',
    displayName: defineMessage({
      id: 'bluePrints.HeadingS.displayName',
      defaultMessage: 'Titre S',
    }),
    icon: <HeadingSIcon />,
    props: {
      label: true,
      hidden: true,
    },
  },

  BodyM: {
    inputName: 'BodyM',
    displayName: defineMessage({
      id: 'bluePrints.BodyM.displayName',
      defaultMessage: 'Paragraphe M',
    }),
    icon: <BodyMIcon />,
    props: {
      longLabel: true,
      hidden: true,
    },
  },
  BodyS: {
    inputName: 'BodyS',
    displayName: defineMessage({
      id: 'bluePrints.BodyS.displayName',
      defaultMessage: 'Paragraphe S',
    }),
    icon: <BodySIcon />,
    props: {
      longLabel: true,
      hidden: true,
    },
  },
}
