import { Typography } from '@material-ui/core'
import { useMemo } from 'react'
import { ReferenceField, useRecordContext, useTranslate } from 'react-admin'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { FullNameField } from 'src/components/FullNameField'
import {
  SfmDateField,
  SfmReferenceArrayField,
  SfmTextField,
} from 'src/components/SfmFields'
import type { FullIntervention } from 'src/types/api/extendedTypes'

import {
  GenerateDataReportButton,
  InterventionDataShowButton,
} from './InterventionDataSection'

export function ListCheckerResult() {
  const intervention = useRecordContext<FullIntervention>()
  const translate = useTranslate()

  const info = useMemo(() => {
    const { statusListChecker } = intervention
    if (statusListChecker) {
      const list = Object.values(statusListChecker)
      return {
        total: list.length,
        verified: list.filter((value) => Boolean(value)).length,
      }
    }
  }, [intervention])

  if (!info) return null
  return (
    <>
      <Typography>
        {translate('resource.interventions.verifiedElements', {
          smart_count: info.verified,
          total: info.total,
        })}
      </Typography>
      <SfmReferenceArrayField
        source="interventionData"
        reference="intervention_datas"
        label=""
        sort={{
          field: 'endTime',
          order: 'desc',
        }}
      >
        <>
          <Typography>
            {translate('resource.interventions.roundData')}
          </Typography>
          <CustomDatagrid rowClick="">
            <SfmTextField
              source="idItemChecker"
              label="resource.intervention_datas.idItemChecker"
            />

            <ReferenceField
              source="operator"
              reference="users"
              label="resource.interventions.operator"
            >
              <FullNameField />
            </ReferenceField>
            <SfmDateField
              source="start"
              label="resource.intervention_datas.start"
              showTime
            />
            <SfmDateField
              source="endTime"
              label="resource.intervention_datas.endTime"
              showTime
            />
            <GenerateDataReportButton intervention={intervention} />

            <InterventionDataShowButton />
          </CustomDatagrid>
        </>
      </SfmReferenceArrayField>
    </>
  )
}
